.ant-modal {
  /** Modal顶部增加黑色border **/
  .ant-modal-content {
    border-top: 4px solid @grey-90;
  }
  /** 关闭按钮背景设为圆形 **/
  .ant-modal-close {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    > .ant-modal-close-x {
      font-size: 12px;
    }
  }
}