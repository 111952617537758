/**************************** Menu
设置menu样式与commonux站点头部菜单样式一致
*/

// font size & default color
.layout-default {
  .ant-menu-horizontal {
    width: 100%;
    font-size: 16px;
  }
  .ant-menu-item a {
    color: @secondary-text;
    &:hover {
      color: @primary-text;
    }
  }
  // change the length of the bottom line
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    left: 0;
    right: 0;
    border-bottom: 4px solid transparent;
  }
  // change the color of the bottom line
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after {
    border-bottom: 4px solid pink;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark)
    > .ant-menu-item-selected:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark)
    > .ant-menu-submenu-selected:hover::after {
    border-bottom: 4px solid @status-red;
  }

  // highlight color
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected a,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected,
  .ant-menu-horizontal:not(.ant-menu-dark)
    > .ant-menu-submenu
    .ant-menu-submenu-title:hover {
    color: @primary-text;
    text-shadow: 0 0 0 @primary-text, 0 0 0.5px @primary-text;
  }
  // popup background color
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item:hover {
    background-color: @grey-10;
  }
  // popup position
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0;
    > .ant-menu-title-content,
    > .ant-menu-submenu-title {
      padding: 0 @space-2x;
      width: 100px;
      text-align: center;
    }
  }
}
