/* ABB voice */
@font-face {
  font-family: 'ABBVoice-light';
  src: url('../assets/fonts/ABBvoice_W_Lt.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Lt.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Lt.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Lt.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Lt.ttf')
      format('truetype'); /* Safari, Android, iOS */
  fontWeight: 300;
  font-style: normal;
}
// @font-face {
//   font-family: ABBVoice;
//   src: url('../assets/fonts/ABBvoice_W_Rg.eot'); /* IE9 Compat Modes */
//   src: url('../assets/fonts/ABBvoice_W_Rg.eot?#iefix')
//       format('embedded-opentype'),
//     /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Rg.woff2') format('woff2'),
//     /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Rg.woff')
//       format('woff'),
//     /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Rg.ttf')
//       format('truetype'); /* Safari, Android, iOS */
//   fontWeight: 400;
//   font-style: normal;
// }
@font-face {
  font-family: ABBVoice;
  src: url("../assets/fonts/ABBvoice_WCNSG_Rg.eot"); /* IE9 Compat Modes */
  src: url("../assets/fonts/ABBvoice_WCNSG_Rg.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../assets/fonts/ABBvoice_WCNSG_Rg.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../assets/fonts/ABBvoice_WCNSG_Rg.woff")
      format("woff"),
    /* Pretty Modern Browsers */ url("../assets/fonts/ABBvoice_CNSG_Rg.ttf")
      format("truetype");
  /* Safari, Android, iOS */

  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ABBVoice-medium';
  src: url('../assets/fonts/ABBvoice_W_md.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_md.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Md.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_md.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Md.ttf')
      format('truetype'); /* Safari, Android, iOS */
  fontWeight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'ABBVoice-bold';
  src: url('../assets/fonts/ABBvoice_W_Bd.eot'); /* IE9 Compat Modes */
  src: url('../assets/fonts/ABBvoice_W_Bd.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('../assets/fonts/ABBvoice_W_Bd.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../assets/fonts/ABBvoice_W_Bd.woff')
      format('woff'),
    /* Pretty Modern Browsers */ url('../assets/fonts/ABBvoice_A_Bd.ttf')
      format('truetype'); /* Safari, Android, iOS */
  fontWeight: 700;
  font-style: normal;
}

body {
  font-family: 'ABBVoice' !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-family: 'ABBVoice' !important;
  // font-family: 'ABBVoice-bold' !important;
}
p {
  // font-family: 'ABBVoice-light' !important;
  font-family: 'ABBVoice' !important;
  font-Weight: 300;
}

/*
自定义样式
*/
#password_help,
#password2_help{
  background: #fff;
  z-index: 99;
}
/*tabs*/
.ant-tabs-ink-bar{
  background: #1f1f1f !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #1f1f1f !important;
  font-weight: 600;
}
.ant-tabs-tab-btn:hover{
  color: #1f1f1f !important;
}

/*文本框*/
.ant-form-item{
  // margin-bottom: 20px !important;
  margin-bottom: 8px !important;
}
.detailPage .ant-form-item{
  margin-bottom: 0px !important;
}
#wrapper .ant-form-item{
  margin-bottom: 20px !important;
}
.ant-form-item-control:hover{
  border-color: #bababa !important;
}
.ant-input{
  border-color: #bababa;
}
.ant-form-item-control-input .ant-input-number:hover,
.ant-form-item-control-input .ant-picker:hover,
.ant-form-item-control-input .ant-select-selector:hover,
.searchItem .ant-form-item-control-input .ant-input-affix-wrapper:hover,
.ant-form-item-control-input .ant-input-password:hover,
.ant-form-item-control-input .ant-input:hover{
  border-color: #bababa !important;
  background: #ebebeb !important;
}
.searchItem .ant-form-item-control-input .ant-input-affix-wrapper-focused{
  background: #fff !important;
}

.ant-form-item-control-input .ant-input-borderless:hover{
  border-color: none !important;
  background: none !important;
}

.ant-input-borderless, 
.ant-input-borderless:hover, 
.ant-input-borderless:focus, 
.ant-input-borderless-focused, 
.ant-input-borderless-disabled, 
.ant-input-borderless[disabled]{
  border-color: transparent !important;
  background: transparent !important;
}
.ant-picker:hover, .ant-picker-focused,
.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused,
.ant-form-item-control-input .ant-form-item-control-input-content .ant-input:focus,
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-number-focused{
  box-shadow: none;
  border-color: #3366ff;
  background: #fff;
}
/*表单验证消息*/
.ant-form-item-explain, .ant-form-item-extra{
  text-align: left;
}
/*表单区块card*/
.ant-card-head-title{
  font-size: 14px !important;
  padding-bottom: 0 !important;
}
.ant-card-extra{
  padding-bottom: 8px !important;
}
.ant-card-body{
  padding-bottom: 0 !important;
}
/*折叠板*/
.ant-collapse-header{
  border-bottom: 1px solid #f0f0f0 !important;
  .ant-collapse-header-text{
    padding-left: 8px;
  }
}
.ant-collapse-content{
  padding: 8px !important;
}
/*底部按钮*/
.bottomBtnPanl{
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 999;
  align-items: center;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #fff;
  border-top: 1px solid #f0f0f0;
  box-shadow: 0 -6px 16px -8px rgb(0 0 0 / 8%), 0 -9px 28px 0 rgb(0 0 0 / 5%), 0 -12px 48px 16px rgb(0 0 0 / 3%);
  transition: width .3s cubic-bezier(.645,.045,.355,1);
  .bottomBtns{
    text-align: right;
    padding: 0 50px;
    .optionBtn{
      margin-left: 16px;
    }
  }
}
/*悬浮按钮*/
.floatButtonPanl{
  border-radius: 50%;
  background-color: #fff;
  transition: background-color .2s;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,segoe ui,Roboto,helvetica neue,Arial,noto sans,sans-serif,apple color emoji,segoe ui emoji,segoe ui symbol,noto color emoji;
  border: none;
  position: fixed;
  cursor: pointer;
  overflow: hidden;
  z-index: 99;
  display: block;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  text-align: center;
  inset-inline-end: 24px;
  inset-block-end: 48px;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  .btnBadge{
    display: unset !important;
  }
  .btnIcon{
    text-align: center;
    margin: auto;
    width: 24px;
    font-size: 24px;
    line-height: 60px;
  }
}
.floatButtonPanl:hover{
  background-color: rgba(0,0,0,.06)
}
/*行类多行文本显示*/
.formItemText{
  padding: 0 11px;
}

.ant-upload-list-picture .ant-upload-list-item-name{
  white-space: pre-wrap;
  line-height: 21px;
}

.ant-form-item-attachment-required .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-btn{
  height: 32px;
  border-radius: 6px;
  font-family: 'ABBVoice' !important;
  span{
    height: 16px;
  }
}
.ant-input
{
  border-radius: 6px;
  font-family: 'ABBVoice' !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 6px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 6px;
}
.ant-form{
  font-family: 'ABBVoice' !important;
}
.ant-col{
  font-family: 'ABBVoice' !important;
}
.ant-table-wrapper table{
  font-family: 'ABBVoice' !important;
}
.ant-descriptions-item-label{
  font-family: 'ABBVoice' !important;
}
.ant-descriptions-item-content{
  font-family: 'ABBVoice' !important;
}
.ant-table-wrapper{
  background-color: white;
}
.pageContainer .ant-card .ant-card-body {
  padding: 0px 8px 0px 8px;
}
.pageContainer .ant-card .ant-card-head{
  padding: 0 16px;
  min-height: 48px;
}
.pageContainer .ant-card .ant-card-head-title{
  // height: 16px;
}
.ant-picker{
  border-radius: 6px;
}
.ant-booltip-open{
  color: white;
}
.ant-menu-item-active{
  color: white;
}
.ant-menu-item:hover{
  color: white;
}

.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
    margin-block: 0px;
    margin-inline: 0px;
    // margin-left: -4px;
}
.treetable .ant-table-wrapper .ant-table-tbody >tr >td >.ant-table-expanded-row-fixed>.ant-table-wrapper:only-child .ant-table{
  margin-block: 0px;
  margin-inline: 0px;
}

.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>td,
.treetable .ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>td
{
  padding: 4px 8px 4px 8px;
}

.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-title, 
.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-footer, 
.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead>tr>th, 
.treetable .ant-table-wrapper .ant-table.ant-table-middle .ant-table-tbody>tr>th, 
.treetable .ant-table-wrapper .ant-table.ant-table-middle tfoot>tr>th
{
  padding: 4px;
}

.treetable .ant-table-wrapper .ant-table.ant-table-middle,
.treetable .ant-table .ant-table-thead > tr > th {
  font-size: 12px;
}
.treetable .treetablededetail .ant-table.ant-table-middle .ant-table-tbody>tr>td,
.treetable .treetablededetail .ant-table.ant-table-middle tfoot>tr>td
{
  padding: 4px 8px 4px 20px;
}

.treetable .treetablededetail .ant-table.ant-table-middle .ant-table-tbody>tr>td .ant-checkbox,
.treetable .treetablededetail .ant-table.ant-table-middle tfoot>tr>td .ant-checkbox
{
  margin-left: -4px;
}


.treetable .ant-table-thead > tr > th, .ant-table-tbody > tr:last-child > td{
  border-bottom-color: #ebebeb;
}
// #root{
//   overflow: hidden;
// }